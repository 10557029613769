export const DungeonsMap = {
	[0]: 'Creston',
	[5]: 'Tower Staircase',
	[7]: 'Upper Tower Staircase',
	[9]: 'Aquatic Aqueduct Dungeons',
	[10]: 'Aquatic Aqueduct',
	[15]: 'Desolate Desert Tombs',
	[16]: 'Desolate Desert',
	[21]: 'Scrapyard Swamp',
	[22]: 'Scrapyard Swamp Treetops',
	[23]: 'Graveyard Grove Tunnels',
	[25]: 'Chaotic Colosseum',
	[26]: 'Graveyard Grove Tunnels',
	[27]: 'Graveyard Grove Tunnels',
	[28]: 'Graveyard Grove Tunnels',
	[29]: 'Graveyard Grove Crypt',
	[30]: 'Graveyard Grove',
	[35]: 'Ascendant Archive',
	[36]: 'Ascendant Archive Memory',
	[37]: 'Archive Memory 1',
	[38]: 'Archive Memory 2',
	[39]: 'Archive Memory 3',
	[40]: 'Archive Memory 4',
	[41]: 'Archive Memory 5',
	[42]: 'Archive Memory 6',
	[43]: 'Archive Memory 7'
};
